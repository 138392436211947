import classnames from "classnames";
import { Component } from "react";
import "./Card.style.scss";

interface CardProps {
  elevation?: "01" | "04" | "08" | "up";
  alignTop?: boolean;
  fullHeight?: boolean;
}

export class Card extends Component<CardProps> {
  public render() {
    const cardClass = classnames(["component-card", `elevation-${this.props.elevation || "01"}`], {
      "align-top": !!this.props.alignTop,
      "full-height": !!this.props.fullHeight,
    });

    return <div className={cardClass}>{this.props.children}</div>;
  }
}

export default Card;
